const HOME = "/admin_bvte"
const ROUTER = {
  SVG_VIEWER: "/svg-viewer",
  HOME: "/admin_chatbot",
  DANG_NHAP: `/`,
  DANG_KY: `${HOME}/dang-ky`,
  DOI_MAT_KHAU: `${HOME}/doi-mat-khau`,
  ACCOUNT: `${HOME}/tai-khoan`,
  POLICY: `/policy`,
  INSTRUCT: "/instruct",
  VIEW_POLICY: "/view-policy",
  RESET_PASS: "/reset-pass/:id",
  CONTRIBUTE: "/contribute",

  //ANONYMOUS

  // ADMIN
  THONG_KE: `${HOME}/thong-ke`,
  LIST_DOMAIN: `${HOME}/domain`,
  TIME: `${HOME}/time`,
  INFO_USER: `${HOME}/info-user`,
  CHUC_NANG: `${HOME}/chuc-nang`,
}
export default ROUTER
