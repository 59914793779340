import React, { useState } from "react"
import { ContributeStyled } from "./css/main"
import { Form, Input, Spin } from "antd"
import { getRegexEmail } from "src/lib/stringsUtils"
import ContributeService from "src/services/ContributeService"
import Notice from "src/components/Notice"

const Contribute = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const handleChangeContribute = async event => {
    try {
      setLoading(true)
      const values = await form.validateFields()
      const res = await ContributeService.createContribute({
        ...values,
      })
      if (!event && res?.isOk) {
      }
      if (res?.isOk) {
        Notice({
          isSuccess: true,
          msg: res?.message,
        })
        form.resetFields()
      }
    } finally {
      setLoading(false)
    }
  }
  return (
    <ContributeStyled>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css"
      ></link>
      <Spin spinning={loading}>
        <section className="contact" id="contact">
          <div className="container">
            <div className="heading text-center">
              <h2>
                Liên hệ với
                <span> chúng tôi </span>
              </h2>
              <p>
                Bạn có ý kiến, thắc mắc hoặc cần hỗ trợ? Hãy liên hệ với chúng
                tôi ngay.
                <br />
                Chúng tôi luôn sẵn lòng lắng nghe và giải đáp mọi câu hỏi của
                bạn.
              </p>
            </div>
            <div className="row">
              <div className="col-md-5">
                <div className="title">
                  <h3>Chi tiết liên hệ</h3>
                  <p>
                    Ý kiến của bạn là nguồn cảm hứng để chúng tôi phát triển.
                  </p>
                </div>
                <div className="content">
                  {/* Info-1 */}
                  <div className="info">
                    <i className="fas fa-mobile-alt" />
                    <h4 className="d-inline-block">
                      PHONE :
                      <br />
                      <span>0796863111</span>
                    </h4>
                  </div>
                  {/* Info-2 */}
                  <div className="info">
                    <i className="far fa-envelope" />
                    <h4 className="d-inline-block">
                      EMAIL :
                      <br />
                      <span>Copa@vncert.vn</span>
                    </h4>
                  </div>
                  {/* Info-3 */}
                  <div className="info">
                    <i className="fas fa-map-marker-alt" />
                    <h4 className="d-inline-block">
                      ADDRESS :<br />
                      <span>
                        Tầng 5, 115 Trần Duy Hưng, Trung Hoà, Cầu Giấy, Hà Nội
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <Form form={form} layout="vertical">
                  <div className="row">
                    <div className="col-sm-6">
                      <Form.Item
                        name="fullname"
                        rules={[
                          {
                            required: true,
                            message: "Bạn chưa nhập tên!",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Tên người gửi"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-sm-6">
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Bạn chưa nhập email!",
                          },
                          {
                            pattern: getRegexEmail(),
                            message: "Email nhập sai định dạng!",
                          },
                        ]}
                      >
                        <Input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-sm-12">
                      <Form.Item
                        name="subject"
                        rules={[
                          {
                            required: true,
                            message: "Bạn chưa nhập tiêu đề!",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Tiêu đề"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="form-group">
                    <Form.Item
                      name="content"
                      rules={[
                        {
                          required: true,
                          message: "Bạn chưa nhập nội dung!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        className="form-control"
                        rows={5}
                        id="comment"
                        placeholder="Nội dung"
                        defaultValue={""}
                      />
                    </Form.Item>
                  </div>
                  <button
                    onClick={() => handleChangeContribute()}
                    className="btn btn-block"
                    type="submit"
                  >
                    Gửi ngay!
                  </button>
                </Form>
              </div>
            </div>
          </div>
        </section>
      </Spin>
    </ContributeStyled>
  )
}

export default Contribute
